<template>
  <signi-error-overlay v-if="error" :message="errorState.message" />
  <template v-else>
    <ers-simulator :exercise-name="exercise?.name" />

    <ers-loading v-if="loading" :progress="0.5" />

    <signi-introduction
      v-if="showIntro"
      application="ERS"
      class="bg-contain bg-left-bottom bg-no-repeat"
      :brief-sections="exercise?.brief"
      :description="exercise?.description ?? 'Loading exercise&hellip;'"
      :progress="progress"
      :style="{ backgroundImage: `url(${publicPath}exercise-background.png)` }"
      :title="exercise?.name ?? '&hellip;'"
      @start="showIntro = false"
    />
  </template>
</template>

<script lang="ts" setup>
import ky from 'ky';
import { computed, ref, watchEffect } from 'vue';
import { ExerciseBrief } from '@signi/common';
import { state as authState, isAuthenticated } from '@signi/magic-link-authentication';
import { useErrorState } from './useErrorState';
import { useLoadingState } from './useLoadingState';

const { state: errorState, error } = useErrorState();
const { state: loadingState } = useLoadingState();

const publicPath = import.meta.env?.BASE_URL;

watchEffect(() => {
  errorState.message = isAuthenticated.value ? undefined : 'You are not authenticated';
});

const loading = computed(() => loadingState.loading || !exercise.value);

const showIntro = ref(true);

const token = authState.accessToken?.raw;
const exercise = ref<ExerciseBrief | undefined>();
ky.get('/api/exercise', token ? { headers: { Authorization: `Bearer ${token}` } } : {})
  .json<ExerciseBrief>()
  .then(async (res) => {
    exercise.value = res;
  })
  .catch((e) => {
    errorState.message = e.message;
  });

// TODO: This is fake. Get progress from Websocket connection if possible
const loadingDuration = 1500; // Same as `GET /api/exercise` delay in handlers.ts
const progress = ref(0);
const interval = setInterval(() => {
  if (progress.value < 1) {
    progress.value += 0.01;
  } else {
    clearInterval(interval);
  }
}, loadingDuration * 0.01);
</script>
